import React from "react";
import MaterialPopper from '@material-ui/core/Popover';
export default class MoreOptions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      top: null,
      left: null,
      loading: false
    };
    this.button = React.createRef();
    this.popup = React.createRef();
  }
  open() {
    var viewportOffset = this.button.current.getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    var top = viewportOffset.top;
    var left = viewportOffset.left;
    this.setState({
      open: !this.state.open,
      left: left,
      top: top,
    })
    if (this.props.onRight == true) {
      setTimeout(() => {
        const el = document.querySelector(".acmMoreOptionsPopup .MuiPopover-paper");
        const st = window.getComputedStyle(el, null);
        var transform = st.getPropertyValue("transform-origin")
        transform = transform.split(" ");
        var x = parseFloat(transform[0].replace("px", ""));
        if(this.props.fromRight != undefined){
          x = x - this.props.fromRight
        }
        el.style.left = parseFloat(el.style.left.replace("px", "")) + x

        if(el.offsetHeight+top >= window.innerHeight){

        }else{
          el.style.top = top
        }
      }, 30)
    }
  }
  onClick(e, value) {
    // prevent doubleClick
    if (this.state.loading) {
      return;
    }
    this.setState({ 
      loading: true,
      open: false
    })
    
    if (this.props.onClick != undefined) {
      this.props.onClick(e, value);
    }

    setTimeout(() => {
      this.setState({ loading: false })
    }, 500)
  }

  render() {
    const options = this.props.options;
    var optionsRender = [];
    if (options != undefined) {
      options.forEach(button => {
        var style = {}
        if(this.props.width != undefined){
          style["width"] = this.props.width+"px"
        }

        optionsRender.push(<div 
        style={style}
        className="moreOptionMenuItem" key={button.value} onClick={e => this.onClick(e, button.value)}>
        {button.icon != undefined && 
        <div className={"moreOptionIcon "+button.icon} />
        }
        {button.label}</div>)
      });
    }
    return (
      <div className="moreOptionsContainer">
        <div className="moreOptionsButton" ref={this.button} onClick={() => this.open()}>
          {this.props.children}
        </div>
        <MaterialPopper
          className="acmMoreOptionsPopup"
          anchorEl={this.button.current}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          {optionsRender}
        </MaterialPopper>
      </div>
    );
  }
}
