import React, { Component } from 'react'
import PureComponent from '../../pure'


import  sAction  from "sAction";

import TextField from "@material-ui/core/TextField";
import InputBase from '@material-ui/core/InputBase';


import Relate from "../../formElements/Relate"
import CheckBox from "../../formElements/CheckBox"
import Text from "../../formElements/Text"
import Select from '../../formElements/Select'
import confProd from "./productLineConfig";
import Currency_id from './../../detailViewFields/Currency_id/Currency_id';
import {Draggable} from "react-beautiful-dnd";
import Image from "./Image";


class QProduct extends PureComponent{
    constructor(props){
        super(props);

        this.quantity = null
    }

    valChange(way, val){
        sAction.updateDetailCustom("field", {way:way, value: val});
    }

    uploadImage = (val) => {
        const way = this.props.way + "/" + this.props.ind + "/picture";
        sAction.updateDetailCustom("field", {way: way, value: val});
    }

    productCallback(way, key, item) {
        if (item.id != undefined && item.id != "") {
            //product template has been selected
            sAction.getProductTemplate(item.id, 'prodName', way, key);
        } else {
            sAction.updateDetailCustom("prodName", {way: way + '/' + key, item: item, template: false});
        }
        // Stary kod. Teoreticky nepotrebny. Smazat. Nechavam ho tu pro jistotu kvuli pokazenymu mergi. - Dan
        // if (item.id != undefined && item.id != "") {
        //     //product template has been selected
        //     const searchData = {
        //         fields: ["id"],
        //         getFields: ["id", "name", "cost_price", "list_price", "discount_price", "currency_id", "description", "mft_part_num", "mft_part_num_int"],
        //         value: item.id,
        //         module : "ProductTemplates"
        //     };
        //
        //     sAction.quickSearch(searchData, data => {
        //         if(data.length > 0){
        //             var line = data[0];
        //             //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
        //             const prefix = way.split("/")[0];
        //
        //             const currency_id = sAction.dataGet(prefix+"/fields/currency_id/value");
        //             // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");
        //
        //             line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);
        //             line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
        //             line.discount_amount = sAction.currencyConvert(line.discount_amount, line.currency_id, currency_id/*, convRate*/);
        //             sAction.updateDetailCustom("prodName", {way:way, item: line, template: true});
        //         }
        //     });
        // }
        // else{
        //     sAction.updateDetailCustom("prodName", {way:way, item: item, template: false});
        // }
    }

    prodInfo(params){
        window.open("#detail/Products/"+params.id, '_blank')
    }

    blurDescription(way, val){
        //this.setState({descFocused : false});
        if (sAction.dataGet(way) !== val) {
            this.valChange(way, val);
        }
    }

    setDescription(productIndex) {
        let line = sAction.dataGet(`${this.props.way}/${productIndex}`)
        line = line.set("descFocused", !line.descFocused)
        sAction.dataSet(`${this.props.way}/${productIndex}`, line)
    }

    render(){
    const conf = confProd.fields;

    var data = this.props.data;
    const way = this.props.way;
    const ind = this.props.ind;
    const itway = way+"/"+ind;
    const readonly = this.props.readonly;
    const isRightPanel = way?.includes("rightPanel");

    const dataProd = {value : data.name, id_value: data.id, searchFields : ["name", "mft_part_num", "mft_part_num_int"]};
    const module = "ProductTemplates";

    var taxClassOptions =[];
    sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
        taxClassOptions.push({value: key, label: lbl});
    });

    var curId = this.props.currency_id;
    if(!curId){
        curId = "-99";
    }
    const discountOptions = [{value: "0", label: sAction.currencies[curId].symbol}, {value: "1", label: "%"}];
    const discountVal = data.discount_select;


    let buttons = [];
    if(data.id && !readonly){
        buttons.push({id: "fullForm", className: "icon-expandIcon qProductProdNameIcon",  onClick: this.prodInfo, params: {id : data.id}});
    }

    let descClass = "productLineDescription";
    const keyPre = Date.now();

    var renderComp = [];

    var self = this;

    var relateClass = "qProductProdNameField";

    if(readonly){
        relateClass += " readonlyRel";
    }


    conf.forEach( function(field){
        if(!field.visible){
            return;
        }

        switch(field.type){
            case "number":
                //Prevzato z cematu #591 pokud si uzivatel vyplni pocet kusu tak se mu neprepise. Kazdopadne pokud
                //by se dotahovalo z produkt templaty i pocet kusu -> smazat tento radek a bude fungovat jako predtim
                if(field.name === "quantity" && self.quantity > 1 &&data[field.name] !== self.quantity){
                    self.valChange(itway + "/" + field.name, self.quantity);
                }

                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            id={field.name}
                            type={!isRightPanel ? "number" : "text"} // v rightpanelu neni misto, tak aby se tam vubec neco zobrazilo
                            defaultValue={data[field.name]}
                            autoComplete="of"
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => {
                                self.valChange(itway + "/" + field.name, ev.target.value);
                                self.quantity = ev.target.value
                            }}
                        />
                    </div>
                );
                break;
            case "relateProd":
                renderComp.push(
                    <div className={relateClass} style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Relate
                            newRecord={false}
                            data={dataProd}
                            module={module}
                            buttons={buttons}
                            key={keyPre+"_"+field.name}
                            InputProps={{readOnly: readonly}}
                            callback={(item) => self.productCallback(way, ind, item)}
                        />
                    </div>
                );
                break;
            case "text":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            className="quoteProductField"
                            id={field.name}
                            type="text"
                            autoComplete="of"
                            disabled={field.disabled || readonly}
                            defaultValue={data[field.name]}
                            key={keyPre+"_"+data[field.name]}
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "tax_class":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={taxClassOptions}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "discount_select":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={discountOptions}
                            defaultValue={discountVal}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, +ev.target.value)}
                        />
                    </div>
                );
            break;
            case "price":
                let val = 0;
                if(field.name == "discount_price"){
                    let discount = sAction.parseNumber(data.discount_amount);
                    if(data.discount_select){
                        discount = (sAction.parseNumber(data.list_price)/100)*discount;
                    }

                    val = sAction.parseNumber(data.list_price) - discount;
                }
                else if(field.name == "total_price"){
                    let discount = sAction.parseNumber(data.discount_amount);
                    if(data.discount_select){
                        discount = (sAction.parseNumber(data.list_price)/100)*discount;
                    }

                    val = (sAction.parseNumber(data.list_price) - discount)*data.quantity;
                }
                else{
                    val = sAction.parseNumber(data[field.name]);
                }

                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                    <TextField
                        className="priceInput quoteProductField"
                        margin="normal"
                        variant="outlined"
                        id={field.name}
                        type="text"
                        key={keyPre+"_"+field.name}
                        autoComplete="of"
                        disabled={field.disabled || readonly}
                        defaultValue={sAction.formatNumber(val)}
                        onBlur={(ev) => self.valChange(itway+"/"+field.name, sAction.parseNumber(ev.target.value))}
                    />
                    </div>
                );
                break;
            case "checkbox":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <CheckBox
                            name={field.name}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            onChange={(value) => self.valChange(itway+"/"+field.name, +value)}
                        />
                    </div>
                );
                break;
        }

    });

    var descComp = null;
    if(data.descFocused){
        descComp = <>
            <Image picture={data.picture} onChange={this.uploadImage} way={itway} />
            <TextField
        className={descClass}
        multiline={true}
        type="text"
        margin="normal"
        variant="outlined"
        rows={10}
        key={keyPre + "_description"}
        onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
        defaultValue={data.description}
        autoFocus={true}
    />
        </>
    }
    else{
        descComp = <div>{data.description}</div>
    }
    var prodlinesIcons = <div className="qProductLineButtonContainer">
            <div className="prodLineIcon"/>
            <div className="prodLineIcon"/>
    </div>;

    if(!readonly){
        prodlinesIcons = <div className="qProductLineButtonContainer">
        <div className="icon-deleteIcon" onClick={() => sAction.updateDetailCustom("delItem", {way: way, index: ind})}/>
        <div className="icon-editDesc" onClick={() => this.setDescription(ind)}/>
        </div>;
    }

        return (
            <Draggable draggableId={`${this.props.groupIndex}-${ind}`} index={ind}>
                {provided => (<div {...provided.draggableProps} ref={provided.innerRef}>
                        <div className="qProductLine">
                            {!readonly ? <div className="qProductLineButtonContainer">
                                <div className="icon-Sortable draggable hoverBlue" {...provided.dragHandleProps}
                                     onMouseDown={() => document.activeElement.blur()}/>
                            </div> : null }
                <div className="qProductLineButtonContainer">
                    <div
                      onClick={() => sAction.rightPanelDetail("Products", data.id)}
                      className={data.id || data.dragged ? "icon-eye hoverBlue" : ""}
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 14, visibility: !isRightPanel ? 'visible' : 'hidden'}}>
                    </div>
                    {data.picture &&
                    <div
                      onClick={() => sAction.popup("image",{url:sAction.param.files+"image/"+data.picture})}
                      className="icon-preview hoverBlue"
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 13}}>
                    </div>}
                </div>
                            {prodlinesIcons}
                            {renderComp}
                        </div>
                        <div className="quoteDescContainer">
                            {descComp}
                        </div>
                    </div>
                )}
            </Draggable>);
    }
}

export default QProduct