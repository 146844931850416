export default function getRequiredFields(fields, ignoredFields = []) {
    const required = {};
    if (!Array.isArray(ignoredFields)) {
        ignoredFields = [];
    }

    for (const field of fields) {
        if ((field.hasOwnProperty('required') && field['required'] === true) && !ignoredFields.includes(field['name'])) {
            required[field['name']] = field['vname'];
        }
    }

    return required;
}
