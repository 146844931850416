export default function importFile(paramData) {
    const prefix = paramData.prefix;
    const data = this.dataGet(prefix).toJS();
    if ( ! data.import) {
        this.error(this.translate('LBL_IMPORT_UPLOAD_CSV_FILE'), this.translate('LBL_IMPORT'));

        return false;
    }

    if (!data?.mapping.fileMapping || !Object.values(data.mapping.fileMapping).some((val) => {return val !== false})) {
        this.error('Namapujte alespoň jedno pole', 'Import');

        return false;
    }

    const mapping = data.mapping.fileMapping;
    const additionalMapping = data.mapping.additionalData;
    const validMapping = this.validateImportMapping(prefix, mapping, additionalMapping);
    if ( ! validMapping) {
        this.error(this.translate('LBL_IMPORT_MAPPING_DUPLICATES'), this.translate('LBL_IMPORT'));

        return false;
    }

    /*
    Pokud jsme dosli az sem, nejsou namapovane zadnu duplicitni sloupce
     */
    delete data.mapping?.duplicates;
    let mappingValues = Object.values(data.mapping.fileMapping);
    /*
    null slouzi jako zastupna hodnota kdyz neni mapovani,
    proto je potreba ho pro kontrolu zda je neco namapovane odstranit
    */
    mappingValues = mappingValues.filter(val => val != null);
    if ( ! mappingValues.length) {
        this.error(this.translate('LBL_IMPORT_NO_MAPPED_FIELDS'), this.translate('LBL_IMPORT'));

        return false;
    }

    const fields =  data.fields.fields;
    let required = this.checkRequiredAreMapped(mappingValues, additionalMapping, fields);
    required = required.map(x => this.translate(x, this.getModuleName()).replace(':', ''));
    let requiredNames = required.join(', ');

    if (required.length) {
        let confirmMessage = this.translate('LBL_IMPORT_REQURIED_FIELDS_MISSING_CONFIRM').replace('{%requiredNames%}', requiredNames);
        this.confirm(confirmMessage, () => {
            this.popupHide();
            startImport(this, prefix);
        });
    } else {
        startImport(this, prefix);
    }
}

function startImport(sAction, prefix) {
    sAction.confirm(sAction.translate('LBL_IMPORT_CONFIRM_IMPORT'), () => {
        sAction.popupHide();
        sAction.uploadCacheFile(sAction.temporaryData.file, (data) => {
            const fileId = data['ids'][0]['file']['id'];
            sAction.importFileMapping({prefix: prefix, fileId: fileId});
            sAction.popupHide();
        });
    });
}
