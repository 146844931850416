export default function rowResizeMove(e){

    if(this.activeResizeRow){
        var actX = e.clientX;
        var vysledekX = actX - this.resizeStart;
        vysledekX  = vysledekX;
        this.resizeRows.forEach(element => {
            element.style.maxWidth = this.resizeWidthStart+vysledekX+"px";
            element.style.minWidth = this.resizeWidthStart+vysledekX < 70 ? '70px' : this.resizeWidthStart+vysledekX+"px";
        });
    }
}