import React from "react";
import PureComponent from "../../pure";
import moment from "moment";
import Button from "../../formElements/Button";
import Select from "../../formElements/Select";
import AcmDate from "../../formElements/AcmDate";

import sAction from "sAction";
import Radio from "../../formElements/Radio";
import TickBox from "../../formElements/TickBox";

export default class CloseQuotePopup extends PureComponent {

  defaultQuoteStage = "Other version";
  closeStates = ["Other version", "Closed Won", "Closed Lost", "Closed canceled client", "Closed canceled me"];
  lostStates = ["Closed Lost", "Closed canceled client", "Closed canceled me"]
  stageOptions = [];

  constructor() {
    super();

    this.closeStates.forEach(state => {
      this.stageOptions.push({ value: state, label: sAction.app_strings.quote_stage_dom[state] });
    });


    this.state = {
      quote_stage: this.defaultQuoteStage,
      date_closed: new Date(),
      opravit_nabidku: true,
      disable_other_option: true,
      uzavrit_pridruzenou_prilezitost: false,
      errors: [],
        lossReason: "",
    };
  }

  handleCloseQuote = () => {
    const errors = [];

    if (!this.state.date_closed) {
      errors.push("date_closed");
    }

    this.setState({ errors });
    if (errors.length) {
      return;
    }

    const date = moment(this.state.date_closed).format("YYYY-MM-DD");
    var params = this.props.data.get("params").toJS();

    sAction.dsClear();
    sAction.dsAdd("set", params.prefix + "/fields/quote_stage/value", this.state.quote_stage);
    sAction.dsAdd("set", params.prefix + "/changes/fields/quote_stage", this.state.quote_stage);
    sAction.dsAdd("set", params.prefix + "/fields/date_quote_closed/value", date);
    sAction.dsAdd("set", params.prefix + "/changes/fields/date_quote_closed", date);
      if(this.state.lossReason){
          sAction.dsAdd("set", `${params.prefix}/fields/loss_reason/value`, this.state.lossReason)
          sAction.dsAdd("set", `${params.prefix}/fields/loss_reason/def/visible`, true)
          sAction.dsAdd("set", `${params.prefix}/changes/fields/loss_reason`, this.state.lossReason)
      }
    sAction.dsProcess();
    params.canSave = true;
    sAction.saveRecord(params, () => {
      sAction.popupHide();
      sAction.unLoad();

      //vytvori se kopie
      if (this.state.quote_stage === "Other version") {
        sAction.quoteNewVersion({
          prefix: params.prefix,
          module: "Quotes",
          id: params.id
        });
      }
    });

    if (this.state.uzavrit_pridruzenou_prilezitost) {
      const opportunity_id = sAction.dataGet(params.prefix+"/fields/opportunity_name/def/id_value");
      if (opportunity_id) {
        const data = {
          opportunity_id,
          sales_stage: this.state.quote_stage,
          date_closed: date,
        };
        sAction.rest.post("opportunityStatusChange", data, returnData => {

        }, false);
      }
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });

    if (name === "quote_stage") {
      switch (value) {
        case "Other version":
          this.setState({ opravit_nabidku: true, disabled_other_option: true });
          break;
        case "Closed Won":
        case "Closed Lost":
        case "Closed canceled client":
        case "Closed canceled me":
          this.setState({ opravit_nabidku: false, disabled_other_option: true });
          break;
      }
    }
  };

  getLossTextarea() {
    if (this.lostStates.includes(this.state.quote_stage)) {
      const data = {
        name: "lossReason",
        value: ""
      }
      return (<>
        <div className="closeQuoteContentTextarea">
          <div className="textareaEditContainer quoteTextAreaContent">
            <div className="inputContainer">
              <label htmlFor="lossReason">{sAction.translate("LBL_LOSS_REASON")}</label>
              <textarea
                  name="lossReason"
                  onChange={this.handleChange}
                  ref={this.input}
                  className="form-control form-control-sm"
                  type="text"
                  id={data.name}
              />
            </div>
          </div>
        </div>

      </>)
    }
    return null
  }

    render() {
        return <div>
            <div className="viewActionPanel">
                <div className="viewActionPanelButtons">
                    <Button className="hoverGreen" onClick={this.handleCloseQuote}>
                        <div className={"icon-saveIcon calHeaderIcon"}/>
                        {sAction.translate("LBL_CLOSE_OPPORTUNITY")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"}/>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
            </div>
            <div className="CloseQuoteContainer">
                <div className="closeQuoteContent">
                    <div className="closeQuoteContentLine">
                        <span>{sAction.translate("LBL_QUOTE_STAGE")}</span>
                        <Select
                            containerClassName={"closeQuoteSelect"}
                            open={false}
                            name="quote_stage"
                            options={this.stageOptions}
                            defaultValue={this.defaultQuoteStage}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="closeQuoteContentLine quoteDateClosed">
                        <span>{sAction.translate("LBL_DATE_CLOSED", "Opportunities")}</span>
                        <AcmDate
                            value={this.state.date_closed}
                            onChange={date => this.setState({date_closed: date})}
                        />
                    </div>
                    {this.getLossTextarea()}
                </div>
                <div className="closeQuoteContentCheckBoxes">
                    <div className="closeQuoteContentBoxLine">
                        <Radio
                            tabIndex={0}
                            disabled={this.state.disable_other_option && this.state.opravit_nabidku === false}
                            onChange={value =>
                                this.handleChange({
                                    target: {
                                        name: "opravit_nabidku",
                                        value
                                    }
                                })
                            }
                            checked={this.state.opravit_nabidku}
                        />

                        {/*Vytvořit opravenou nabídku*/}
                        {sAction.translate("LBL_CREATE_REVISED_QUOTE", "Quotes")}
                    </div>
                    <div className="closeQuoteContentBoxLine">
                        <Radio
                            tabIndex={0}
                            disabled={this.state.disable_other_option && this.state.opravit_nabidku === true}
                            onChange={value =>
                                this.handleChange({
                                    target: {
                                        name: "opravit_nabidku",
                                        value: !value
                                    }
                                })
                            }
                            checked={!this.state.opravit_nabidku}
                        />

                        {/*Neopravovat tuto Nabídku*/}
                        {sAction.translate("LBL_DONT_CREATE_REVISED_QUOTE", "Quotes")}
                    </div>
                    <div className="closeQuoteContentBoxLine">
                        <TickBox
                            tabIndex={0}
                            disabled={this.state.opravit_nabidku}
                            onChange={checked =>
                                this.handleChange({
                                    target: {
                                        name: "uzavrit_pridruzenou_prilezitost",
                                        value: checked
                                    }
                                })
                            }
                            checked={this.state.uzavrit_pridruzenou_prilezitost}
                        />

                        {/*Uzavřít přidruženou příležitost*/}
                        {sAction.translate("LBL_CLOSE_RELATED_OPPORTUNITY", "Quotes")}
                    </div>
                </div>
            </div>
        </div>
    }
}

