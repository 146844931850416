export default function checkRequiredAreMapped(mapping, additionalMapping, fields) {
    let requiredFields = this.getRequiredFields(fields, ['id']);
    for (const mapped of mapping) {
        if(requiredFields.hasOwnProperty(mapped)) {
            delete requiredFields[mapped];
        }
    }

    additionalMapping.forEach((value) => {
        if(requiredFields.hasOwnProperty(value.field)) {
            delete requiredFields[value.field];
        }
    })

    return Object.values(requiredFields);
}
