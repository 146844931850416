import React, { Component } from 'react'
import PureComponent from '../../pure'
import SubpanelHeaderInv from "../../subpanel/subpanelHeaderInvitees";
import InvRow from './invRow';
import InvCandidateRow from './invCandidateRow';
import sAction from "sAction";
class InviteesContainer extends PureComponent {
    constructor() {
        super();

        this.fname = React.createRef();
        this.email = React.createRef();
        this.title = React.createRef();
        this.phonew = React.createRef();
        this.mobile = React.createRef();

        this.state = {
            show: true,
            open: true,
            readonly: false,
        }

        this.searchInvCntPrev = 0;
        this.searchInvCnt = 0;
        this.searchParamsPrev = "";
        this.searchParams = "";

        this.typingTimer;
        this.doneTypingInterval = 400;

        this.waiting = false;
    }

    componentDidUpdate(){
        if (this.searchParamsPrev !== this.searchParams || 
           (this.searchParamsPrev === this.searchParams && this.searchInvCntPrev !== this.searchInvCnt )){
            this.searchInv()
        }
    }

    searchInv = () => {
        const params = {
            first_name: this.fname.current.value.trim(),
            email_address: this.email.current.value,
            title: this.title.current?.value,
            prefix: this.props?.detailWay,
            phone_work: this.phonew.current?.value,
            phone_mobile: this.mobile.current?.value
        }

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => this.triggerSearch(params), this.doneTypingInterval);
        this.waiting = true;
    }

    triggerSearch = (params) => {
        sAction.searchInvitees(params, this.props.prefix);
        this.waiting = false;

        let paramsPrint = "";
        Object.keys(params).forEach(key => {
            paramsPrint += params[key]
        });
        this.searchParamsPrev = this.searchParams;
        this.searchParams = paramsPrint;
    }

    resetSearchInv = (self = this) => {
        self.fname.current.value = "";
        self.email.current.value = "";
        self.title.current.value = "";
        self.phonew.current.value = "";
        self.mobile.current.value = "";
        self.searchInv();
    }

    newContact(parentData) {
        sAction.detailPredefinedFields = [];
        // let detailWay = this.props.detailWay;//.replace('meetingView', 'detailView').replace('callView', 'detailView');
        let detailWay = parentData.detailWay;

        let parent_type = sAction.dataGet(detailWay + "/fields/parent_type/value");
        let parent_type2 = sAction.dataGet(detailWay + "/fields/parent_name/def/parent_type_value");
        let parent_name = sAction.dataGet(detailWay + "/fields/parent_name/value");
        let parent_id = sAction.dataGet(detailWay + "/fields/parent_name/def/id_value");
        if ((parent_type == "Accounts" || parent_type2 == "Accounts") && parent_id) {
            let relate_simple = {
                fieldName: "account_name",
                name: "account_id",
                value: {
                    name: parent_name,
                    id: parent_id
                }
            }

            sAction.detailPredefinedFields.push({
                type: "relate_simple",
                ...relate_simple
            });
        }

        sAction.popupDetail({
            module: "Contacts",
            record: "",
            saveCallback: (conData) => {
                let conParams = {
                    module: "Contacts",
                    id: conData.record,
                    action: "getBeanInfo",
                    fields: ["email1", "phone_work", "phone_mobile"],
                }

                sAction.rest.post("customAction", conParams, (resp) => {
                    if (resp.status) {
                        sAction.dsClear();
                        resp.message.email = resp.message.email1;
                        sAction.dsAdd("add", parentData.prefix + "/relatedInv/Contacts", resp.message);
                        sAction.dsProcess();
                    }
                })
            },
            exitCallback: data => {

            }
        });
    }

    isEmpty() {
        if (this.fname?.current?.value.trim()
            || this.email?.current?.value.trim()
            || this.title?.current?.value.trim()
            || this.phonew?.current?.value.trim()
            || this.mobile?.current?.value.trim()) {
            return false;
        }
        return true;
    }

    set = (param, val) => {
        let state = this.state
        state[param] = val
        this.setState({ state })
    }

    render() {
        const data = this.props.data.relatedInv;
        const searchInv = this.props.data.searchInv;
        const prefix = this.props.prefix;
        const pomClass = this.props.pomClass;

        this.searchInvCntPrev = this.searchInvCnt;
        this.searchInvCnt = searchInv.Contacts.length + searchInv.Leads.length + searchInv.Users.length;

        // warning: pokud server bude pořád vracet neprázdné pole tak toto to zacyklí
        if (this.isEmpty() && (searchInv.Users.length !== 0 || searchInv.Contacts.length !== 0 || searchInv.Leads.length !== 0)) {
            this.searchInv();
        }

        let invArr = [];

        let ind = 0;
        data.Users.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix} />;
            invArr.push(row);
        });

        ind = 0;
        data.Contacts.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix} />;
            invArr.push(row);
        });

        ind = 0;
        data.Leads.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix} />;
            invArr.push(row);
        });

        let searchRes = [];
        if (!this.isEmpty()) {

            ind = 0;
            searchInv.Users.forEach(elem => {
                searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix} />);
            });

            ind = 0;
            searchInv.Contacts.forEach(elem => {
                searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix} />);
            });

            ind = 0;
            searchInv.Leads.forEach(elem => {
                searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix} />);
            });
        }

        const newReloadBtn = <a
            className="invReloadBtn"
            id="invReloadBtn"
            target="_blank"
            title={sAction.translate("LBL_REFRESH")}
            onClick={() => this.resetSearchInv()} >

            <span className={"icon-refresh"} />
        </a>;

        let dataHeader = {
            rows: { page: 0 },
            module: "Invitees",
            subpanelName: sAction.translate("LBL_PARTICIPANTS_TAB", "Calendar"),
            newContact: this.newContact,
            parentData: { detailWay: this.props.detailWay, prefix: this.props.prefix, self: this },
            dbLinkRelDef: { module: "Contacts", relName: "contacts", type: "relate" },
            allowMaxSize: false,
            refreshSearch: this.resetSearchInv,
            def: {
                get: (param) => { return this.state[param]; },
                set: this.set
            },
            rows: []
        }

        return (
            <>
                <div className="subpanelContainer customSubpanelInv" data-way={prefix} >
                    <div className={
                        dataHeader.def.get("show") == false ? "subpanel hidden" : "subpanel"
                    } ref={this.subpanel} >

                        <SubpanelHeaderInv
                            data={dataHeader}
                            way={"view/subpanels/0"}
                            prefix={prefix}
                            records={[]}
                            iconColor={sAction.colorTemplate[2]}
                        />

                        <div className={"pageContainer calInvComponent " + pomClass + (!this.state.open && " hidden")}>
                            <div className="detailViewContainer">
                                <div className="detailViewTabContent">
                                    <div className="relInvContainer">
                                        <table className="invTable">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th className='tbMeeting' title={sAction.translate("LBL_ACCEPT_STATUS", "Meetings")}>{sAction.translate("LBL_ACCEPT_STATUS", "Meetings")}</th>
                                                    <th className='tbName' title={sAction.translate("LBL_NAME_HEADLINE", "Contacts")}>{sAction.translate("LBL_NAME_HEADLINE", "Contacts")}
                                                        <input className="invSearchInput" ref={this.fname} type='text' onChange={() => this.searchInv()} />
                                                    </th>
                                                    <th className='tbTitle' title={sAction.translate("LBL_TITLE", "Contacts")}>{sAction.translate("LBL_TITLE", "Contacts")}
                                                        <input className="invSearchInput" ref={this.title} type='text' onChange={() => this.searchInv()} />
                                                    </th>
                                                    <th className='tbEmail' title={sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}>{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}
                                                        <input className="invSearchInput" ref={this.email} type='text' onChange={() => this.searchInv()} />
                                                    </th>
                                                    <th className='tbWPhone' title={sAction.translate("LBL_PHONE_WORK", "Contacts")}>{sAction.translate("LBL_PHONE_WORK", "Contacts")}
                                                        <input className="invSearchInput" ref={this.phonew} type='text' onChange={() => this.searchInv()} />
                                                    </th>
                                                    <th className='tbPhone' title={sAction.translate("LBL_PHONE_MOBILE", "Contacts")}>{sAction.translate("LBL_PHONE_MOBILE", "Contacts")}
                                                        <input className="invSearchInput" ref={this.mobile} type='text' onChange={() => this.searchInv()} />
                                                    </th>
                                                    <th align="center">{newReloadBtn}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchRes.length > 0 && (
                                                    <>
                                                        {searchRes}
                                                        <tr className='headerSec'>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{sAction.translate("LBL_PARTICIPANTS_TAB", "Calendar")}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </>
                                                )}

                                                {!searchRes.length && !this.waiting && (this.fname?.current?.value.trim() || this.email?.current?.value.trim() || this.title?.current?.value.trim() || this.phonew?.current?.value.trim() || this.mobile?.current?.value.trim()) ?
                                                    <tr className='headerSecAlert'>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{sAction.translate("LBL_NOT_FOUND", "Calendar")}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    : undefined}
                                                {invArr}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default InviteesContainer
