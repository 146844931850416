export default function validateImportMapping(way, mapping, additionalMapping) {
    this.dsClear();
    let haveDuplicates = false;
    let mappingValues = {};

    mapping.forEachObject((value, key) => {
        if (!value?.value) {
            return;
        }
        if (mappingValues.hasOwnProperty(value.value)) {
            this.dsAdd('set', way + '/mapping/duplicates/' + key, true);
            this.dsAdd('set', way + '/mapping/duplicates/' + mappingValues[value.value], true);
            haveDuplicates = true;
        } else {
            this.dsAdd('set', way + '/mapping/duplicates/' + key, false);
        }

        mappingValues[value.value] = key;
    })

    let additionalMappingValues = {};
    additionalMapping.forEach((value, key) => {
        if (additionalMappingValues.hasOwnProperty(value.field)) {
            this.dsAdd('set', way + '/mapping/additionalData/' + key + '/duplicate', true);
            this.dsAdd('set', way + '/mapping/additionalData/' + additionalMappingValues[value.field] + '/duplicate', true);
            haveDuplicates = true;
        } else {
            this.dsAdd('set', way + '/mapping/additionalData/' + key + '/duplicate', false);
        }

        if (mappingValues.hasOwnProperty(value.field)) {
            this.dsAdd('set', way + '/mapping/additionalData/' + key + '/duplicate', true);
            this.dsAdd('set', way + '/mapping/duplicates/' + mappingValues[value.field], true);

            haveDuplicates = true;
        }

        additionalMappingValues[value.field] = key;
    })

    this.dsProcess();

    return !haveDuplicates;
}
