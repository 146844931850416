import React from "react";
import PureComponent from "../../pure";
import TabSelect from "../../formElements/TabSelect";
import Relate from "../../detailViewFields/Relate/Relate";
import { DataConstructors } from "../../../store/DataConstructors";
import sAction from "sAction";
import InputText from "../../formElements/InputText";
import Enum from '../../defaultFields/Enum/Enum';
import SimpleFormFromArray from '../../formElements/SimpleFormFromArray';

class MapAdditionalData extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            relateData: null,
            fieldName: '',
            fieldIndex: '',
            tabIndex: '',
        };
    }

    createNewColumn(data) {
        const inputValue = data.tabSelect.type === 'relate' ? data.field.name : data.field;
        const fieldData = {
            field: data.tabSelect.value,
            inputValue: inputValue,
            label: data.tabSelect.label,
            value: data.field,
            visible: true,
            way: this.props.data.get('prefix') + '/mapping/additionalData',
        };
        sAction.dsClear();
        sAction.dsAdd('add', this.props.data.get('prefix') + '/mapping/additionalData', fieldData);
        sAction.dsAdd('add', this.props.data.get('prefix') + '/mappedRelates', data.tabSelect.value);
        sAction.dsProcess();
        sAction.popupHide();
    }

    selectField(e) {
        this.setState({fieldName: e.value, fieldIndex: e.index, tabIndex: e.activeTab});
    }

    createFieldsOptions(fieldsArray) {
        let options = {};

        fieldsArray.forEachObject((value, key) => {
            options[key] = [];
            value.forEach((field, index) => {
                options[key].push({
                    label: sAction.translate(field.vname, this.props.data.get('module')),
                    index: index,
                    value: field.name,
                    ...field,
                });
            });
        });

        return options;
    }

    getFormButtons() {
        return {
            button: {
                onClick: (data) => {this.createNewColumn(data)},
                label: 'LBL_IMPORT_CREATE_COLUMN',
            }
        };
    }

    getFormFields() {
        const options = this.props.data.get?.('options')?.toJS?.();

        let returnData = {
            tabSelect: {
                type: 'tabSelect',
                containerClassName: 'withBorder',
                options: options,
                onChange: (e) => {this.selectField(e)},
                label: 'LBL_IMPORT_SELECT_FIELD',
                required: true,
            }
        };

        if (this.state.fieldName && this.state.fieldIndex != null) {
            returnData.field = {
                required: true,
                ...options[Object.keys(options)[this.state.tabIndex]][this.state.fieldIndex],
            };
        }

        return returnData;
    }

    render() {
        return (
            <div className='importPopupContainer'>
                <div className='acmPopupHeader'>{sAction.translate('LBL_IMPORT_ADD_COLUMN')}</div>
                <div className='importRelateSelectorContainer'>
                    <SimpleFormFromArray
                        useTextAsDefault={true}
                        fields={this.getFormFields()}
                        buttons={this.getFormButtons()}
                    />
                </div>
            </div>
        );
    }
}

export default MapAdditionalData;
